#homepage-tell-us {
    .tell-us-container {
        max-width: 690px;
    }

    @media screen and (max-width: 991px) {
        svg {
            width: 28px;
            height: auto;
        }
    }

    .email-contact{
        cursor: pointer;
    }

    .email-contact, .wispr-contact {
        

        &:hover {
            color: #86868B;
        }
    }

    @media screen and (max-width: 350px){
        .contact-container{
            flex-direction: column;
        }
    }
}