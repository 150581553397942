// ============================================= 16 ======================================================
.font-size-16 {
    font-size: 16px;
  
    @media screen and (max-width: 1024px) {
      font-size: 15px;
    }
  
    @media screen and (max-width: 991px) {
      font-size: 14px;
    }
  
    @media screen and (max-width: 575px) {
      font-size: 12px;
    }
  }
// ============================================= 20 ======================================================
.font-size-20 {
    font-size: 20px;
  
    @media screen and (max-width: 1024px) {
      font-size: 18px;
    }
  
    @media screen and (max-width: 991px) {
      font-size: 16px;
    }
  
    @media screen and (max-width: 767px) {
      font-size: 14px;
    }
  
    @media screen and (max-width: 575px) {
      font-size: 12px;
    }
  }
  
  // ======================================================== 24 ====================================================
  .font-size-24 {
    font-size: 24px;
  
    @media screen and (max-width: 1024px) {
      font-size: 22px;
    }
  
    @media screen and (max-width: 991px) {
      font-size: 20px;
    }
  
    @media screen and (max-width: 767px) {
      font-size: 18px;
    }
  
    @media screen and (max-width: 575px) {
      font-size: 16px;
    }
  
    @media screen and (max-width: 320px) {
      font-size: 14px;
    }
  }
  
  // ===================================================== 28 =========================================================
  
  .font-size-28 {
    font-size: 28px;
  
    @media screen and (max-width: 1300px) {
      font-size: 24px;
    }
    @media screen and (max-width: 1024px) {
      font-size: 22px;
    }
  
    @media screen and (max-width: 991px) {
      font-size: 20px;
    }
  
    @media screen and (max-width: 767px) {
      font-size: 18px;
    }
  
    @media screen and (max-width: 575px) {
      font-size: 16px;
    }
  }
  
  // ============================================================== 56 =======================================================
  
  .font-size-36 {
    font-size: 36px;
  
    @media screen and (max-width: 1024px) {
      font-size: 34px;
    }
  
    @media screen and (max-width: 991px) {
      font-size: 32px;
    }
  
    @media screen and (max-width: 767px) {
      font-size: 28px;
    }
  
    @media screen and (max-width: 575px) {
      font-size: 26px;
    }
  
    @media screen and (max-width: 320px) {
      font-size: 24px;
    }
  }
  
  // ============================================================== 48 =======================================================
  
  .font-size-48 {
    font-size: 48px;
  
    @media screen and (max-width: 1024px) {
      font-size: 46px;
    }
  
    @media screen and (max-width: 991px) {
      font-size: 42px;
    }
  
    @media screen and (max-width: 767px) {
      font-size: 40px;
    }
  
    @media screen and (max-width: 575px) {
      font-size: 36px;
    }
  
    @media screen and (max-width: 320px) {
      font-size: 32px;
    }
  }
  // ============================================================== 56 =======================================================
  
  .font-size-56 {
    font-size: 56px;
  
    @media screen and (max-width: 1024px) {
      font-size: 50px;
    }
  
    @media screen and (max-width: 991px) {
      font-size: 48px;
    }
  
    @media screen and (max-width: 767px) {
      font-size: 40px;
    }
  
    @media screen and (max-width: 575px) {
      font-size: 32px;
    }
  
    @media screen and (max-width: 320px) {
      font-size: 26px;
    }
  }
  
  // ======================================================== 64 ====================================================
  
  .font-size-64 {
    font-size: 64px;
  }
  
  @media screen and (max-width: 1600px) {
    .font-size-64 {
      font-size: 60px;
    }
  }
  @media screen and (max-width: 1400px) {
    .font-size-64 {
      font-size: 56px;
    }
  }
  @media screen and (max-width: 1200px) {
    .font-size-64 {
      font-size: 50px;
    }
  }
  @media screen and (max-width: 991px) {
    .font-size-64 {
      font-size: 40px;
    }
  }
  @media screen and (max-width: 500px) {
    .font-size-64 {
      font-size: 32px;
    }
  }
  @media screen and (max-width: 300px) {
    .font-size-64 {
      font-size: 28px;
    }
  }
  
  // ======================================================== 120 ====================================================
  
  .font-size-120 {
    font-size: 120px;
  }
  
  @media screen and (max-width: 1500px) {
    .font-size-120 {
      font-size: 100px;
    }
  }
  
  @media screen and (max-width: 1200px) {
    .font-size-120 {
      font-size: 80px;
    }
  }
  
  @media screen and (max-width: 991px) {
    .font-size-120 {
      font-size: 60px;
    }
  }
  
  @media screen and (max-width: 600px) {
    .font-size-120 {
      font-size: 40px;
    }
  }
  
  // ======================================================== font-family ====================================================
  
  @font-face {
    font-family: "font-gotham-bold";
    src: url("./Gotham-Bold.otf");
  }
  
  @font-face {
    font-family: "font-gotham-book";
    src: url("./Gotham-Book.ttf");
  }
  
  @font-face {
    font-family: "font-gotham-medium";
    src: url("./GothamMedium.otf");
  }
  
  @font-face {
    font-family: "font-gotham-light";
    src: url("./Gotham-Light.otf");
  }
  
  .font-gotham-bold {
    font-family: font-gotham-bold !important;
  }
  
  .font-gotham-book {
    font-family: font-gotham-book !important;
  }
  
  .font-gotham-medium {
    font-family: font-gotham-medium !important;
  }
  
  .font-gotham-light {
    font-family: font-gotham-light !important;
  }
  
  