#changelog {
  .p-02 {
    padding: 0 2px;
  }
  .p-03 {
    padding: 0 3px;
  }
  .ico-container {
    margin-block: 0.25rem;
    padding: 0.5rem;
    height: 130px;
    cursor: pointer;
    position: relative;
  }

  .device-select-container {

    margin-bottom: 4rem;

    .ico-container:not(:last-child):after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      width: 1px;
      background-color: #e5e5e5;
    }

    @media screen and (max-width: 767px) {
      margin-bottom: 2rem;
    }
  }

  .device-container {

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;

    color: #ffffff;
    background-color: #af4aff;
    padding: 0.25rem 0.5rem;
    max-height: 32px;
    height: 100%;
    width: 100%;
    max-width: 150px;
    border-radius: 8px;

    @media screen and (max-width: 500px){
      width: 110px;
      max-width: 110px;
    }
  }

  .change-log-fix {
    background-color: #af4aff;
    max-width: 74px;
    max-height: 32px;
    height: 32px;
    width: 100%;
    border-radius: 8px;
    margin-top: 25px;

    @media screen and (max-width: 500px){
      height: 24px;
    }
  }
  .change-log-new {
    background-color: #20deb0;
    max-width: 74px;
    max-height: 32px;
    height: 32px;
    width: 100%;
    border-radius: 8px;

    @media screen and (max-width: 500px){
      height: 24px;
    }
  }
  .change-log-update-type {
    background-color: #ddda08;
    margin-top: 25px;
    max-height: 32px;
    height: 32px;
    width: 100%;
    border-radius: 8px;
    padding: 0 15px;

    @media screen and (max-width: 500px){
      height: 24px;
    }
  }
  .update {
    display: flex;
    justify-content: center;
    align-items: center;

    color: #ffffff;
    background-color: #3092df;
    max-height: 32px;
    height: 32px;
    width: 220px;
    max-width: 220px;
    text-align: center;
    border-radius: 8px;
    padding: 0.25rem 0.5rem;

    @media screen and (max-width: 500px){
      height: 24px;
    }

    @media screen and (max-width: 500px){
      width: 150px;
      max-width: 150px;
    }
  }
  .automatic-update {
    @extend .update;
  }


  .manual-update {
    @extend .update;
  }

  .titles-container {
    @media screen and (max-width: 500px){
      flex-direction: column;
      justify-content: flex-start !important;
      align-items: flex-start !important;
      gap: .5rem;
      
    }
  }

  .gray-color {
    color: #86868B
  }

  //accordion

  .accordion-button {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding-left: 0;
    padding-right: 0;
    padding-block: 0.25rem !important;
    color: #000000 !important;

    &::after {
      display: none !important;
    }

    &:focus {
      box-shadow: none;
    }
    .close-icon {
      min-width: 24px;
      width: 24px;
      height: auto;
      transition: 0.2s;
      transform: rotate(45deg);
    }
  }

  .collapsed {
    .close-icon {
      transform: rotate(0) !important;
    }
  }

  .accordion-button:not(.collapsed) {
    box-shadow: none;
  }

  .accordion-body {
    padding-bottom: 0.5rem;
    padding-top: 0;
  }

  .accordion-collapse {
    a {
      text-decoration: none;
      margin-bottom: 0.25rem !important;
      transition: 0.2s;

      &:hover {
        color: white !important;
      }
    }
  }
  .accordion,
  .accordion-item,
  .accordion-button {
    background-color: transparent !important;
    border: none;
    cursor: pointer;
  }

  .accordion-item {
    background-color: #f5f5f7 !important;
    padding: 1rem;
    border: 1px solid #c9c9d1;
    border-radius: 12px;
    margin-bottom: 1rem;
  }

  // @media screen and (max-width: 991px) {
  //   .hidr-ico {
  //     width: 25px;
  //     height: auto;
  //   }

  //   .impulse-ico {
  //     width: 27px;
  //     height: auto;
  //   }

  //   .basm-ico {
  //     width: 60px;
  //     height: auto;
  //   }

  //   .wispr-ico {
  //     width: 50px;
  //     height: auto;
  //   }

  //   .os-ico {
  //     width: 50px;
  //     height: auto;
  //   }

  //   .apps-ico {
  //     width: 50px;
  //     height: auto;
  //   }
  // }
  @media screen and (max-width: 991px) {
    .ico-container {
      height: 100px;
    }
    
    .hidr-ico {
      width: 18px;
      height: auto;
    }

    .impulse-ico {
      width: 20px;
      height: auto;
    }

    .basm-ico {
      width: 50px;
      height: auto;
    }

    .wispr-ico {
      width: 35px;
      height: auto;
    }

    .os-ico {
      width: 40px;
      height: auto;
    }

    .apps-ico {
      width: 40px;
      height: auto;
    }
  }

  @media screen and (max-width: 550px) {
    .ico-container {
      height: 80px;
      font-size: 12px;
    }
    
    .hidr-ico {
      width: 14px;
      height: auto;
    }

    .impulse-ico {
      width: 16px;
      height: auto;
    }

    .basm-ico {
      width: 40px;
      height: auto;
    }

    .wispr-ico {
      width: 28px;
      height: auto;
    }

    .os-ico {
      width: 30px;
      height: auto;
    }

    .apps-ico {
      width: 30px;
      height: auto;
    }
  }

  @media screen and (max-width: 350px) {
    .ico-container {
      height: 70px;
      font-size: 11px;
      padding: .2rem;
    }
    
    .hidr-ico {
      width: 12px;
      height: auto;
    }

    .impulse-ico {
      width: 14px;
      height: auto;
    }

    .basm-ico {
      width: 32px;
      height: auto;
    }

    .wispr-ico {
      width: 22px;
      height: auto;
    }

    .os-ico {
      width: 22px;
      height: auto;
    }

    .apps-ico {
      width: 22px;
      height: auto;
    }
  }
}
